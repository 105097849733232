<template>
	<v-dialog v-model="dialog" persistent max-width="900px">
		<v-card>
			<!-- Header -->
			<v-card-title
				class="d-flex justify-space-between border-bottom primary pt-2 pb-1"
			>
				<span class="subtitle-1 white--text">
					<span v-if="!isEditing">Добавление нового дома</span>
					<span v-else>Редактирование дома</span>
				</span>
				<v-btn color="dark" @click="closeModal(false)" icon>
					<v-icon v-if="!isEditing" color="white">mdi-arrow-left</v-icon>
					<v-icon v-else color="white">mdi-close</v-icon>
				</v-btn>
			</v-card-title>

			<!-- Progress bar -->
			<v-progress-linear
				v-if="loading"
				color="primary"
				height="5"
				indeterminate
			></v-progress-linear>

			<!-- Body -->
			<v-card-text v-else>
				<v-container>
					<v-form ref="form" :disabled="disabled" lazy-validation>
						<template v-if="base_role !== 'administrator'">
							<!-- Основные данные -->
							<v-row class="pb-3 border-bottom">
								<v-col cols="12" class="pb-0">
									<span class="subtitle-1 font-weight-regular black--text">
										Основные данные
									</span>
								</v-col>
								<!-- Адрес -->
								<v-col cols="12" sm="6" md="4">
									<Label>Адрес</Label>
									<v-text-field
										v-model="data.apartment_address"
										class="border-all mt-2"
										:disabled="disabledAddress"
										solo
										dense
										hide-details
										flat
										required
										:rules="rules.required"
									></v-text-field>
								</v-col>
								<!-- РКА -->
								<v-col cols="12" sm="6" md="4">
									<Label>РКА</Label>
									<v-text-field
										v-model="data.rca"
										v-mask="'################'"
										class="border-all mt-2"
										solo
										dense
										hide-details="auto"
										flat
										:rules="rules.rca"
									></v-text-field>
								</v-col>
								<!-- Город -->
								<v-col cols="12" sm="6" md="4">
									<Label>Город</Label>
									<v-autocomplete
										v-model="data.city_id"
										class="border-all mt-2"
										solo
										dense
										hide-details
										flat
										required
										:filter="addressFilter"
										:items="catalog.cities"
										item-text="name"
										item-value="id"
										:rules="rules.required"
									></v-autocomplete>
								</v-col>

							<!-- Детали дома -->
							<!-- <v-row class="pb-3">
								<v-col cols="12" class="pb-0">
									<span class="subtitle-1 font-weight-regular black--text">
										Детали дома
									</span>
								</v-col> -->
								<!-- Количество квартир -->
								<!-- <v-col cols="12" sm="6" md="4">
									<Label>Количество квартир</Label>
									<v-text-field
										v-model="data.actual_number_apartments"
										type="number"
										class="border-all mt-2"
										min="0"
										:max="maxNum"
										solo
										dense
										hide-details="auto"
										flat
										required
										:rules="rules.number.concat(rules.maxNumber)"
									></v-text-field>
								</v-col> -->
								<!-- Количество НП -->
								<!-- <v-col cols="12" sm="6" md="4">
									<Label>Количество НП</Label>
									<v-text-field
										v-model="data.actual_number_non_residential_premises"
										class="border-all mt-2"
										type="number"
										min="0"
										:max="maxNum"
										solo
										dense
										hide-details="auto"
										flat
										required
										:rules="rules.number.concat(rules.maxNumber)"
									></v-text-field>
								</v-col> -->
								<!-- Количество паркомест -->
								<!-- <v-col cols="12" sm="6" md="4">
									<Label>Количество паркомест</Label>
									<v-text-field
										v-model="data.actual_number_parking_place"
										type="number"
										min="0"
										class="border-all mt-2"
										solo
										dense
										hide-details="auto"
										flat
										:rules="rules.number.concat(rules.maxNumber)"
										required
									></v-text-field>
								</v-col> -->
							</v-row>

							<!-- Орган управления -->
							<v-row>
								<v-col cols="12" class="pb-0">
									<span class="subtitle-1 font-weight-regular black--text">
										Орган управления
									</span>
								</v-col>
								<!-- Наименование ОСИ -->
								<v-col cols="12" sm="6" md="4">
									<Label>Наименование ОСИ</Label>
									<TextField v-model="data.name_apw" class="mt-2"></TextField>
								</v-col>
								<!-- Адрес ОСИ -->
								<v-col cols="12" sm="6" md="4">
									<Label>Адрес ОСИ</Label>
									<TextField v-model="data.address_apw" class="mt-2"></TextField>
								</v-col>
								<!-- Телефон ОСИ -->
								<v-col cols="12" sm="6" md="4">
									<Label>Телефон ОСИ</Label>
									<TextField
										v-model="data.phone_chairman"
										:placeholder="phoneCode+'-'"
										v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
										class="mt-2"
										hide-details="auto"
										:rules="rules.phone"
									></TextField>
								</v-col>
								<!-- Наименование УК -->
								<v-col cols="12" sm="6" md="4">
									<Label>Наименование УК</Label>
									<TextField v-model="data.name_ksk" class="mt-2"></TextField>
								</v-col>
								<!-- Адрес УК -->
								<v-col cols="12" sm="6" md="4">
									<Label>Адрес УК</Label>
									<TextField v-model="data.address_ksk" class="mt-2"></TextField>
								</v-col>
								<!-- Телефон УК -->
								<v-col cols="12" sm="6" md="4">
									<Label>Телефон УК</Label>
									<TextField
										v-model="data.phone_ksk"
										:placeholder="phoneCode+'-'"
										v-mask="phoneCode == '+7' ? phoneCode+'-###-###-##-##' : phoneCode+'-###-###-####'"
										class="mt-2"
										hide-details="auto"
										:rules="rules.phone"
									></TextField>
								</v-col>
							</v-row>
						</template>
						<!-- Администратор -->
						<template v-else>
							<v-row>
								<!-- Адрес -->
								<v-col cols="12" sm="6" md="6">
									<Label>Адрес</Label>
									<v-text-field
										v-model="data.apartment_address"
										class="border-all mt-2"
										solo
										dense
										hide-details
										flat
										required
										:rules="rules.required"
									></v-text-field>
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<Label>Наименование</Label>
									<TextField v-model="	data.name" class="mt-2"></TextField>
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<Label>УК</Label>
									<v-autocomplete
										v-model="data.ksk_id"
										class="border-all mt-2"
										solo
										clearable
										item-text="name"
										item-value="id"
										:items="kskList.records"
										dense
										hide-details
										flat
									>
										<template v-slot:append-item>
											<div v-if="kskList.page < kskList.pages" v-intersect="onIntersect" class="pa-4 secondary--text">
												Загрузка ...
											</div>
										</template>
									</v-autocomplete>
								</v-col>
								<v-col cols="12" sm="6" md="6">
									<Label>Город</Label>
									<v-autocomplete
										v-model="data.city_id"
										class="border-all mt-2"
										solo
										item-text="name"
										item-value="id"
										:items="catalog.cities"
										dense
										hide-details
										flat
									>
										<template v-slot:append-item>
											<div v-if="kskList.page < kskList.pages" v-intersect="onIntersect" class="pa-4 secondary--text">
												Загрузка ...
											</div>
										</template>
									</v-autocomplete>
								</v-col>
								<!-- <v-col cols="12" sm="6">
									<Label>Город</Label>
									<v-autocomplete
										v-model="data.city_id"
										class="border-all mt-2"
										solo
										dense
										hide-details
										flat
										required
										:filter="addressFilter"
										:items="catalog.cities"
										item-text="name"
										item-value="id"
										:rules="rules.required"
									></v-autocomplete>
								</v-col> -->
							</v-row>
						</template>

						<!-- Alert -->
						<v-row>
							<v-col cols="12" class="py-0">
								<v-alert
									v-if="alert"
									class="mb-0"
									:type="alert.color"
									:color="alert.color"
									outlined
									dense
								>
									{{ alert.message }}
								</v-alert>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>
			<!-- Footer -->
			<v-card-actions class="pt-0 pb-5 d-flex justify-end">
				<v-btn
					class="subtitle-2 text-capitalize"
					color="primary"
					depressed
					:loading="isAddingHouse"
					@click="addHouse"
				>
					<span v-if="!isEditing">Добавить</span>
					<span v-else>Сохранить</span>
				</v-btn>
			</v-card-actions>
		</v-card>

		<ModalConfirm
			v-if="showBindModal"
			@close-modal="bindHouse"
			title="Привязка дома к УК"
		>
			<template #body>
				<div class="d-flex flex-column justify-center text-center align-center">
					<span
						>Данный адрес уже имеется в системе. Вы можете привязать его к Вашей
						УК</span
					>
					<strong>{{ data.apartment_address }}</strong>
				</div>
			</template>
		</ModalConfirm>
	</v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import axios from "axios";
import ModalConfirm from "@/components/app/ModalConfirm";

export default {
	name: "HousesTableModalNext",
	components: { ModalConfirm },
	props: {
		houseData: {
			required: false,
		},
		isEditing: {
			type: Boolean,
			default: false,
		},
	},

	data() {
		return {
			alert: null,
			disabledAddress: false,
			dataEdit: {},
			data2: {},
			data: {
				apartment_address: "",
				ksk_id: '',
				// actual_number_apartments: "",
				// actual_number_parking_place: "",
				// actual_number_non_residential_premises: "",
				// address_type: "",
				city_id: "",
				// district: "",
				// house_class: "",
				// house_number: "",
				// name: "",
				rca: "",
				// s_building_id: "",
				// year_construction: "",
				name_apw: "",
				address_apw: "",
				phone_apw: "",
				name_ksk: "",
				address_ksk: "",
				phone_ksk: "",
				bin_apw: "",
				fio_chairman: "",
				phone_chairman: "",
				// category_residential_complex_id: "",
				// postcode: "",
			},
			disabled: false,
			dialog: true,
			isAddingHouse: false,
			showBindModal: false,
			loading: true,
			maxNum: 999999,
			kskList: {
				records: [],
				page: 1,
				pages: 1
			},
			currentYear: new Date().getFullYear(),
			rules: {
				bin: [v => v?.length === 0 || v?.length === 12 || "Введите 12 цифр"],
				number: [
					v =>
						v === null ||
						!String(v)?.length ||
						(v && v >= 0) ||
						"Введите положительное значение",
				],
				maxNumber: [
					v =>
						v === null ||
						!String(v)?.length ||
						(v >= 0 && v < 999999) ||
						"Слишком большое значение",
				],
				required: [v => !!v || ""],
				phone: [
					v => !!v || "",
					v => this.phoneCode === '+7' ? ((v && v.length === 16) || this.$t("login.errors.incorrectPhoneFormat")) : (v && v.length > 14) || this.$t("login.errors.incorrectPhoneFormat"),
				],
				postalcode: [
					v => v?.length === 0 || v?.length >= 5 || "Некорректное количество символов",
				],
				rca: [
					v => v?.length === 0 || v?.length === 16 || "Введите 16 символов",
				],
				year_construction: [
					v =>
						v?.length === 0 ||
						(v?.length > 0 && +v > 0 && +v <= this.currentYear) ||
						`Допустимые значения от 1 до ${this.currentYear}`,
				],
			},
		};
	},
	computed: {
		...mapGetters({
			catalog: "catalog/GET_CATALOG",
			ksk_id: "auth/GET_KSK_ID",
			houses: "ksk/GET_HOUSES",
			token: "auth/GET_TOKEN",
			base_role: "auth/GET_USER_ROLE",
			phoneCode: "auth/GET_USER_PHONE_CODE",
		}),
	},
	watch: {
		data: {
			handler(newVal) {
				this.findDiff(this.data2, newVal)
			},
			deep: true
		},
		alert(val) {
			if (!val) return;
			setTimeout(() => {
				this.alert = null;
			}, 4000);
		},
		'data.rca' () {
			if(this.base_role !== 'administrator' && this.data.rca.length == 16) {
				console.log('rca length 16 - ', this.data.rca)
				this.checkRca()
			}
		}
	},
	methods: {
		getKskList() {
			this.$api.dropdowns.load_ksk_list_confirm(this.kskList.page).then(res => {
				this.kskList.page = res.page
				this.kskList.pages = res.pages
				this.kskList.records = [
					...this.kskList.records,
					...res
				]
			})
		},
		onIntersect () {
			this.kskList.page += 1
			this.getKskList()
		},
		findDiff(a, b) {
			this.dataEdit = Object.entries(b).filter(([key, val]) => a[key] !== val && key in a).reduce((a, [key, v]) => ({...a, [key]: v}), {});
		},
		async checkRca() {
			this.disabledAddress = false
			try {
				const res = await this.$api.house.check_house_rca(this.data.rca);
				if(res) {
					this.data.apartment_address = res.name
					if(res.name.length) {
						this.disabledAddress = true
					}
					
				}
			} catch (error) {
			} finally {
				this.loading = false;
			}
		},
		// Валидация
		customValidation() {
			let message;

			if (!this.$refs.form.validate()) {
				this.alert = {
					color: "error",
					message: "Заполните все выделенные поля",
				};
				return false;
			}

			if (message) {
				this.alert = {
					color: "error",
					message,
				};
				return false;
			}
			return true;
		},

		// Добавление дома
		async addHouse() {
			this.alert = null;

			if (!this.customValidation()) return;

			// this.data.apartments_count = this.data.actual_number_apartments;

			if (this.isEditing) {
				this.editHouse();
				return;
			}
		
			console.log("adding");
			if(this.base_role !== 'administrator') {
				this.data.ksk_id = this.ksk_id
			}
			try {
				this.isAddingHouse = true;
				console.log("new house", this.data);
				await this.$api.ksk.add_house(this.data);

				for (const key in this.data) {
					this.data[key] = "";
				}

				this.alert = {
					color: "success",
					message: "Дом добавлен",
				};

				this.$refs.form.resetValidation();

				setTimeout(() => this.closeModal(true), 500);
			} catch (error) {
				if (error.message.includes("id")) {
					error.message = "Данный дом уже имеется в системе";
				}
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.isAddingHouse = false;
			}
		},

		// Привязка дома
		async bindHouse(isConfirmed) {
			this.showBindModal = false;

			if (!isConfirmed) {
				this.closeModal();
				return;
			}

			try {
				const res = await this.$api.ksk.bind_house(this.data._id);
				console.log(res);
				this.alert = {
					color: "success",
					message: "Дом привязан",
				};
				setTimeout(() => this.closeModal(true), 500);
			} catch (error) {
				console.error(error);
			}
		},

		async editHouse() {
			this.alert = null;
			this.dataEdit._method = "patch"
			console.log("editing");
			try {
				this.isAddingHouse = true;
				if(this.base_role == 'administrator') {
					await this.$api.ksk.edit_house(this.houseData.id, this.data);
				} else {
					await this.$api.ksk.edit_house(this.$route.params.id, this.dataEdit);
				}
				

				this.alert = {
					color: "success",
					message: "Изменения сохранены",
				};

				this.$refs.form.resetValidation();

				setTimeout(() => this.closeModal(true), 500);
			} catch (error) {
				this.alert = {
					color: "error",
					message: error.message || "Произошла ошибка. Повторите еще раз.",
				};
			} finally {
				this.isAddingHouse = false;
			}
		},

		closeModal(confirm = false) {
			this.dialog = false;
			this.$emit("update-info");
			this.$emit("close-modal", confirm);
		},
	},
	async created() {
		this.loading = false;
		if(this.base_role === 'administrator') {
			this.getKskList()
		}
		if(this.isEditing) {
			this.data2 = this.data
			if(this.base_role !== 'administrator') {
				this.data = {
					...this.data,
					rca: this.houseData.rca,
					name: this.houseData.name,
					ksk_id: this.houseData.ksk_id,
					city_id: this.houseData.city._id,
					name_apw: this.houseData.name_apw,
					phone_chairman: this.houseData.phone_chairman,
					address_apw: this.houseData.address_apw,
					address_ksk: this.houseData.address_ksk,
					phone_ksk: this.houseData.phone_ksk,
					name_ksk: this.houseData.name_ksk,
					_method: "patch",
					apartment_address:
						this.houseData.address_name || this.houseData.apartment_address,
				};
			} else {
				this.data = {
					...this.data,
					ksk_id: this.houseData.ksk_id,
					name: this.houseData.name,
					city_id: this.houseData.city._id,
					name_apw: this.houseData.name_apw,
					phone_apw: this.houseData.phone_apw,
					address_apw: this.houseData.address_apw,
					phone_ksk: this.houseData.phone_ksk,
					name_ksk: this.houseData.name_ksk,
					_method: "patch",
					apartment_address:
						this.houseData.address_name || this.houseData.apartment_address,
				}
			}
		}

	// 	if (
	// 		this.data.year_construction?.length > 0 &&
	// 		isNaN(+this.data.year_construction)
	// 	) {
	// 		this.data.year_construction = "";
	// 	}

	// 	Object.keys(this.data).forEach(key => {
	// 		if (key.includes("number")) {
	// 			if (!this.data[key]) {
	// 				this.data[key] = null;
	// 			} else {
	// 				this.data[key] = Number(this.data[key]);
	// 			}
	// 		} else if (this.data[key] === null) {
	// 			this.data[key] = "";
	// 		}
	// 	});

	// 	if (!this.isEditing) {
	// 		try {
	// 			const res = await this.$api.house.check_house_bind({
	// 				id: this.data.id,
	// 				rca: this.data.rca,
	// 			});
	// 			this.data._id = res._id;
	// 			this.showBindModal = this.disabled = true;
	// 		} catch (error) {
	// 		} finally {
	// 			this.loading = false;
	// 		}
	// 	}

	// 	this.loading = false;
	},
};
</script>
